import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>There is no coffee to be found here</h1>
    <p>If you are looking for the bathroom, it is downstairs. Otherwise, the wifi password is NoirNoir... Twice with capital N, no space</p>
  </Layout>
)

export default NotFoundPage
